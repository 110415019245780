import logo from './logo.svg';
import './App.css';
import OperationComponent from './OperationComponent'


function App() {
  return (
      <>
        <OperationComponent/>
    </>
  );
}

export default App;
